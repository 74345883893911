import React, { Component } from 'react';
import styled from 'styled-components';
//import { graphql } from 'gatsby';

import { JoinUs } from '../components';
import { Slider } from '../containers';
import Axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../components/Modal/Modal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  justify-content: center;
`;

// const RowContainer = styled.div`
//   @media ${medias.laptopMin} {
//     display: flex;
//     flex-direction: row;
//     align-self: center;
//     justify-content: space-between;
//   }

//   @media ${medias.laptopMax} {
//     align-items: center;

//     & > :not(:first-child) {
//       display: none;
//     }
//   }
//   display: flex;
//   margin: 0 0 30px 0;
//   padding: 0px 35px 0px 35px;
// `;

const JoinWrapper = styled.div`
  min-height: 340px;
`;

// const UlList = styled.ul`
//   list-style: none;
//   padding: 0;
//   padding-inline-start: 0;
// `;

const BlueLine = styled.div`
  background-color: #53adb3;
  height: 134px;
`;

const WhiteLine = styled.div`
  position: absolute;
  background-color: white;
  height: 110px;
  width: 90%;
  margin-top: -2rem;
  margin-left: 5% !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  padding: 20px;

  @media (max-width: 768px) {
    height: 187px;
    margin-top: -3rem;
  }

  @media (max-width: 448px) {
    height: 210px;
  }
`;

const BlogDiv = styled.div`
  margin-top: 6rem;
  @media (max-width: 768px) {
    margin-top: 12rem;
  }
`;

const StyledTitle = styled.h3`
  font-size: 0.9rem;
`;

const StyledP = styled.p`
  font-size: 0.9rem;
`;

const StyledInput = styled.input`
  border: 1px solid #c7cbcf;
  font-size: 0.7rem !important;
  @media (max-width: 768px) {
    font-size: 0.9rem !important;
    padding: 5px;
    margin-bottom: 5px;
  }
`;

const StyledButton = styled.button`
  font-size: 0.7rem !important;
  /* @media (max-width:970px) {
    font-size: 0.5rem !important;
  } */
  @media (max-width: 768px) {
    font-size: 0.9rem !important;
  }
`;

class HomePage extends Component {
  constructor() {
    super();

    this.state = {
      posts: [],
      loading: false,
      resource: {
        name: '',
        email: '',
      },
      showModal: false,
      modalMessage: '',
      modalTitle: '',
      loadingEbook: false,
    };

    this.shuffle = array => {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    };

    this.fetchBlogPosts = async () => {
      this.setState({
        loading: true,
      });
      let arrayPosts = [];
      // eslint-disable-next-line no-undef
      const posts = await Axios.get(
        'https://blog.certificadotrust.com/wp-json/wp/v2/posts'
      );
      const shufflePosts = this.shuffle(posts.data);
      if (shufflePosts.length) {
        for (let i = 0; i < 3; i++) {
          let post = posts.data[i];
          let url = post._links['wp:attachment'][0].href;
          const posts2 = await Axios.get(url);
          await arrayPosts.push({
            title: post.title.rendered,
            image: posts2.data.length ? posts2.data[0].guid.rendered : '',
            link: post.link,
            date: post.date,
            content: post.content.rendered,
          });
          if (i === 2) {
            this.setState({
              posts: arrayPosts,
              loading: false,
            });
          }
        }
      }
    };

    this.sendEbook = async event => {
      this.setState({
        loadingEbook: true,
      });
      event.preventDefault();
      const { resource } = this.state;
      console.log(resource);
      let formData = new FormData();
      formData.append('ebd_downloads[]', '33|Ebook: Local para Eventos');
      formData.append('ebd_settings[]', 'title|Ebook: Local para Eventos');
      formData.append('ebd_settings[]', 'delivered_as|send email');
      formData.append('ebd_settings[]', 'hide_form|no');
      formData.append('ebd_settings[]', 'radio|no');
      formData.append(
        'ebd_settings[]',
        'from_email|certificado@certificadotrust.com'
      );
      formData.append('ebd_settings[]', 'from_name|Certificado Trust');
      formData.append('ebd_settings[]', 'link_format|_blank');
      formData.append('ebd_settings[]', 'item_id|1');
      formData.append('ebd_settings[]', 'form_id|wpcf7-f36-p37-o1');
      formData.append('_wpcf7', '36');
      formData.append('_wpcf7_version', '5.1.4');
      formData.append('_wpcf7_locale', 'pt_BR');
      formData.append('_wpcf7_unit_tag', 'wpcf7-f36-p37-o1');
      formData.append('_wpcf7_container_post', '37');
      formData.append('_wpcf7_container_post', '37');
      formData.append('your-name', resource.name);
      formData.append('your-email', resource.email);

      const config = {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=----WebKitFormBoundaryBxB9Yhqd5JPBegG6',
          Accept: '*/*',
        },
      };

      // eslint-disable-next-line no-undef
      const response = await Axios.post(
        'https://blog.certificadotrust.com/wp-json/contact-form-7/v1/contact-forms/36/feedback',
        formData,
        config
      );

      if (response.data) {
        this.setState({
          modalTitle: 'Ebook: Locais de Evento',
          modalMessage: response.data.message,
          showModal: true,
          loadingEbook: false,
          resource: {
            email: '',
          },
        });
      }
    };

    this.handleInputChange = (event, field) => {
      const { resource } = this.state;
      const value = event.target.value;
      this.setState({
        resource: {
          ...resource,
          [field]: value,
        },
      });
    };
  }

  componentDidMount() {
    this.fetchBlogPosts();
  }

  render() {
    const {
      resource,
      showModal,
      modalTitle,
      modalMessage,
      loadingEbook,
    } = this.state;
    console.log(loadingEbook);

    return (
      <Wrapper>
        <Slider />
        <StyledContainer>
          <JoinWrapper>
            <JoinUs />
          </JoinWrapper>
          <div>
            <BlueLine />
            <WhiteLine className="row">
              <div className="col-md-5">
                <StyledTitle>Baixe nosso E-Book gratuito!</StyledTitle>
                <StyledP>
                  O guia definitivo para escolher o local para festas!
                </StyledP>
              </div>
              <div className="col-md-6">
                {loadingEbook && (
                  <div className="text-center">
                    <ClipLoader
                      sizeUnit={'px'}
                      size={30}
                      color={'#123abc'}
                      loading={loadingEbook}
                    />
                    <p>Aguarde...</p>
                  </div>
                )}
                {!loadingEbook && (
                  <div className="row">
                    <StyledInput
                      placeholder="Nome"
                      className="col-md-4"
                      value={resource.name}
                      onChange={event => this.handleInputChange(event, 'name')}
                    />
                    <StyledInput
                      placeholder="Email"
                      className="col-md-5"
                      value={resource.email}
                      onChange={event => this.handleInputChange(event, 'email')}
                    />
                    <StyledButton
                      className="btn btn-info col-md-3"
                      onClick={event => this.sendEbook(event)}
                    >
                      Baixar E-Book
                    </StyledButton>
                  </div>
                )}
              </div>
            </WhiteLine>
          </div>
          <BlogDiv>
            {/* <div>
              <h3 className="text-center mb-5">Blog do TRUST</h3>
            </div>
            {posts.length === 0 && loading && (
              <div className="text-center mt-4">
                <ClipLoader
                  sizeUnit={'px'}
                  size={30}
                  color={'#123abc'}
                  loading={loading}
                />
                <p>Carregando posts do blog...</p>
              </div>
            )}
            <RowContainer>
              <UlList className="row">
                {posts.length > 1 &&
                  posts.map((item, key) => (
                    <li className="col-sm-12 col-md-6 col-lg-4 m-0" key={key}>
                      <BlogPost
                        key={key}
                        title={item.title}
                        image={item.image}
                        link={item.link}
                        date={item.date}
                        content={item.content}
                      />
                    </li>
                  ))}

                {posts.length === 1 &&
                  posts.map((item, key) => (
                    <li className="col-md-12 m-0" key={key}>
                      <BlogPost
                        key={key}
                        title={item.title}
                        image={item.image}
                        link={item.link}
                        date={item.date}
                        content={item.content}
                      />
                    </li>
                  ))}
              </UlList>
              {/* {posts.length > 1 && posts.map((item, key) => (
              <BlogPost key={key} title={item.title} image={item.image} link={item.link} date={item.date} content={item.content}/>
            ))} 
            </RowContainer>*/}
          </BlogDiv>
        </StyledContainer>
        <Modal
          {...this.props}
          show={showModal}
          onHide={() => this.setState({ showModal: false })}
          title={modalTitle}
          body={modalMessage}
        />
      </Wrapper>
    );
  }
}

export default HomePage;
