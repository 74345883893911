import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';

import { theme } from '../../styles/theme';
import RightArrow from 'react-icons/lib/fa/angle-right';
import LeftArrow from 'react-icons/lib/fa/angle-left';
import { SearchBar } from '../../components';
import { absoluteCenter } from '../../utils/styleSnippets';
import { medias } from '../../utils/mediaQueries';
import { hexToRgb } from '../../utils/hexToRgb';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media ${medias.largeMin} {
    position: relative;
    display: block;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  @media ${medias.phoneMax} {
    font-size: 1.5em;
  }

  font-size: 2rem;
  font-weight: 900;
  color: ${theme.colors.secondary};
  margin: 0;
  text-align: center;
`;

const StyledBackgroundSection = styled(BackgroundImage)`
  padding: 4rem 1rem;
  @media ${medias.largeMin} {
    padding: 20vh 1.5rem 30vh;
  }
  ::after {
    background-position: center 50%;
    background-size: cover;
  }
`;

const StyledLine = styled.div`
  @media ${medias.phoneMax} {
    height: 5px;
    margin-top: 17px;
    margin-bottom: 20px;
  }

  margin-top: 27px;
  margin-bottom: 40px;
  height: 7px;
  width: 118px;
  background-color: ${theme.colors.primary};
`;

const Text = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.85em;
  }

  font-size: ${theme.fontSizeLg}px;
  color: ${theme.colors.white};
  font-weight: bold;
  width: 40%;
  text-align: center;
  text-shadow: 0 2px 2px ${hexToRgb(theme.colors.secondary, 0.3)};
`;

const StyledSearchBar = styled(props => <SearchBar {...props}/>)`
  @media ${medias.largeMin} {
    ${absoluteCenter}
    top: 80%;
  }
`;

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <LeftArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        left: '20%',
        zIndex: 1,
        top: '52.5%',
      }}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <RightArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        right: '20%',
        zIndex: 1,
        top: '52.5%',
      }}
      onClick={onClick}
    />
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  dotsClass: 'slick-dots',
  fade: true,
  className: 'custom-slider',
};

class SliderComponent extends Component {
  render() {
    const { content } = this.props;
    const slides = content.map(({ title, text, backgroundImg }) => (
      <StyledBackgroundSection
        key={backgroundImg}
        Tag="section"
        fluid={backgroundImg}
      >
        <Content>
          <Title>{title}</Title>
          <StyledLine />
          <Text>{text}</Text>
        </Content>
      </StyledBackgroundSection>
    ));

    return (
      <Wrapper>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {slides}
        </Slider>
        <StyledSearchBar isHome={true}/>
      </Wrapper>
    );
  }
}

SliderComponent.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      backgroundImg: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default SliderComponent;
