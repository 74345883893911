import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { useStateValue } from '../../state';
import { Button, MaterialInput } from '../../components';
import { theme } from '../../styles/theme';
import { LockIcon, SignupIcon } from '../../components/icons';
import { PasswordModal } from '../../containers/';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 0.5em;
`;

const StyledInput = styled(props => <MaterialInput {...props} />)`
  width: 90%;
`;

const StyledButton = styled(props => <Button {...props} />)`
  border-color: ${theme.colors.text_heading};
  margin: 40px 0 30px 0;
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  font-weight: normal;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const FooterText = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
`;

const FooterItem = styled.div`
  display: flex;
  align-items: center;
`;

const LoginForm = () => {
  const [
    {
      visibility,
      forms: { loginForm },
    },
    dispatch,
  ] = useStateValue();
  const { email, password } = loginForm;

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeLoginForm',
      newText,
      field,
    });
  };

  const onClickJoin = () => {
    dispatch({
      type: 'toggleLoginModal',
      newVisibility: !visibility.loginModal,
    })
  };

  return (
    <Wrapper>
      <StyledInput
        value={email}
        label="E-mail"
        onChange={event => handleChange(event.target.value, 'email')}
      />
      <StyledInput
        value={password}
        label="Senha"
        type="password"
        onChange={event => handleChange(event.target.value, 'password')}
      />
      <StyledButton
        text="ENTRAR"
        click={() => {
          // @TODO adapt this to save login endpoint's return into state instead of hardcoded values
          dispatch({
            type: 'registerSession',
            userId: 'userid',
            balance: 150,
            userType: 'bride',
          });

          dispatch({
            type: 'toggleLoginModal',
            newVisibility: !visibility.loginModal,
          })

          // if (!email || !password) {
          //   toast.error('Todos os campos são obrigatórios!');
          //   return false;
          // }

          // if (!REGEX_EMAIL.test(email)) {
          //   toast.error('Digite um e-mail válido!');
          //   return false;
          // }

          return true;
        }}
      />
      <FooterWrapper>
        <FooterItem>
          <LockIcon />
          <FooterText
            onClick={() =>
              dispatch({
                type: 'togglePasswordModal',
                newVisibility: true,
              })
            }
          >
            Esqueci minha senha
          </FooterText>
        </FooterItem>
        <FooterItem>
          <SignupIcon />
          <StyledLink to="/junte-se">
            <FooterText onClick={onClickJoin}>Faça parte</FooterText>
          </StyledLink>
        </FooterItem>
      </FooterWrapper>
      <PasswordModal />
    </Wrapper>
  );
};

export default LoginForm;
