// Alguns pedacinhos de CSS altamente reutilizáveis 
// O que você estiver repetindo muito, coloca aqui 😉

export const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const absoluteCenter = `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

export const absoluteHorizontalCenter = `
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const absoluteVerticalCenter = `
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
