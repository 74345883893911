import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { theme } from '../../styles/theme';
import { useStateValue } from '../../state';
import {
  LoginChainIcon,
  RingsIcon,
  FormIcon,
  CirclesIcon,
} from '../../components/icons';
import LoginModalSection from './LoginModalSection';
import { ExitButton } from '../../components/styled';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 30%;
  min-height: 20%;
  background-color: ${theme.colors.grey1};
  border: 1px solid ${theme.colors.grey2};
  z-index: 3;
  border-radius: 5px;

  display: flex;
  flex: 1;
  flex-direction: column;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(LoginChainIcon)`
  height: 85px;
  width: 85px;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1em;
`;

const Title = styled.div`
  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.grey3};
  text-align: left;
  font-weight: bold;
`;

const SubTitle = styled.div`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
`;

const TitleAndSub = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 1.5em;
`;

const SectionsContainer = styled.div`
  margin-top: 5%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginModal = () => {
  const node = useRef();
  const [{ visibility }, dispatch] = useStateValue();

  const handleClick = event => {
    if (!node.current) {
      return;
    }
    if (node.current.contains(event.target)) {
      return;
    }
    dispatch({
      type: 'toggleLoginModal',
      newVisibility: false,
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <>
      {visibility.loginModal &&
        <Wrapper ref={node}>
          <ExitButton
           left={'93%'}
           top={'3%'}
            onClick={() => dispatch({
              type: 'toggleLoginModal',
              newVisibility: !visibility.loginModal,
            })}
          >
            x
          </ExitButton>
          <ModalHeader>
            <IconsWrapper>
              <StyledIcon />
            </IconsWrapper>
            <TitleAndSub>
              <Title>
                Login
              </Title>
              <SubTitle>
                Acesso Restrito
              </SubTitle>
            </TitleAndSub>
          </ModalHeader>
          <SectionsContainer>
            <LoginModalSection
              open={visibility.fianceeAccess}
              action={() => dispatch({
                type: 'toggleFianceeAccess',
                newVisibility: !visibility.fianceeAccess,
              })}
              icon={<RingsIcon />}
              text="Acesso dos Noivos"
            />
            <LoginModalSection
              action={() => dispatch({
                type: 'toggleSupplierAccess',
                newVisibility: !visibility.supplierAccess,
              })}
              open={visibility.supplierAccess}
              icon={<FormIcon />}
              text="Acesso do Fornecedor"
            />
            <LoginModalSection
              action={() => dispatch({
                type: 'toggleFranchiseAccess',
                newVisibility: !visibility.franchiseAccess,
              })}
              open={visibility.franchiseAccess}
              icon={<CirclesIcon />}
              text="Acesso do Franqueado"
            />
          </SectionsContainer>
        </Wrapper>
      }
    </>
  );
}

LoginModal.propTypes = {
};

export default LoginModal;
