import React from 'react';
import styled from 'styled-components';
import DownArrow from 'react-icons/lib/fa/angle-down';

import { theme } from '../../styles/theme';
import LoginForm from './LoginForm';

const Line = styled.div`
  width: 90%;
  height: 1px;
  background-color: ${theme.colors.grey2};
`;

const SectionText = styled.p`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  width: 80%;
  margin: 10px 0 10px 0;
`;

const SectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

const SecondaryWrapper = styled.div`
  display: flex;
  flex: 1;
  max-width: 60%;
  align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  margin: 10px 0 10px 0;
`;

const StyledArrow  = styled(DownArrow)`
  transform: ${({ isDown }) => isDown ? 'rotate(180deg)' : 'none'};
  transition: .3s ease;
  &:hover {
    cursor: pointer;
  }
`;

const Section = props => {
  const { open, icon, text, action } = props;

  return (
    <>
      <Line />
      <SectionContent>
        <SecondaryWrapper>
          <IconWrapper>
            {icon}
          </IconWrapper>
          <SectionText>
            {text}
          </SectionText>
        </SecondaryWrapper>
        <StyledArrow
          onClick={action}
          isDown={open}
        />
      </SectionContent>
      {open && <LoginForm />}
    </>
  );
}

export default Section
