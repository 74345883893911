import React, { useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { theme } from '../styles/theme';
import { useStateValue } from '../state';
import { RedButton } from '../components/styled';
import { ExitButton } from '../components/styled';
import { REGEX_EMAIL } from '../utils/regex';

const Wrapper = styled.div`
  position: absolute;
  width: 90%;
  min-height: 45%;

  background-color: ${theme.colors.grey1};
  border: 1px solid ${theme.colors.grey2};
  z-index: 5;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  width: 92%;
`;

const Line = styled.div`
  height: 2px;
  background-color: ${theme.colors.grey2};
  margin: 0 0 35px 0;
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
`;

const Title = styled.h4`
  text-align: left;
  margin: 0;
`;

const InputTitle = styled.p`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  text-align: left;
  margin: 10px 0 5px 0;
`;

const Text = styled.p`
  font-size: ${theme.fontSize * 1.13}px;
  text-align: left;
  margin: 15px 0 15px 0;
`;

const PasswordModal = () => {
  const [{ visibility, forms }, dispatch] = useStateValue();

  useEffect(() => {
    return () => handleChange('', 'forgotPasswordEmail');
  }, []);

  const onClickConfirm = () => {
    const destinationAddress = forms.loginForm.forgotPasswordEmail;

    if (!destinationAddress) {
      toast.error('Favor inserir seu endereço de e-mail!');
      return false;
    }

    if (!REGEX_EMAIL.test(destinationAddress)) {
      toast.error('Digite um e-mail válido!');
      return false;
    }

    return true;
    // @Todo call email endpoint sending value from global state
  };

  const onClickClose = () => {
    dispatch({
      type: 'togglePasswordModal',
      newVisibility: false,
    });
  };

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeLoginForm',
      newText,
      field,
    });
  };

  return (
    <>
      {visibility.passwordModal && (
        <Wrapper>
          <InnerContainer>
            <ExitButton onClick={onClickClose} left={'96%'} top={'2.5%'} />
            <Title>ALTERAR A SENHA</Title>
            <Text>Entre com o email para recuperar a senha:</Text>
            <InputTitle>Email</InputTitle>
            <StyledInput
              value={forms.loginForm.forgotPasswordEmail}
              type="text"
              onChange={event =>
                handleChange(event.target.value, 'forgotPasswordEmail')
              }
            />
            <Line />
            <RedButton text="CONFIRMAR" click={onClickConfirm} />
          </InnerContainer>
        </Wrapper>
      )}
    </>
  );
};

export default PasswordModal;
