import React from 'react';
import styled from 'styled-components';
// import { toast } from 'react-toastify';

import MaterialInput from '../components/MaterialInput'; //check this
import { RedButton } from '../components/styled';
import { useStateValue } from '../state';
// import { theme } from '../styles/theme';
// import { REGEX_EMAIL } from '../utils/regex';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled(MaterialInput)`
  width: 90%;
`;

const ContactUsForm = () => {
  const [, dispatch] = useStateValue();

  const changeEvent = (event, type) => {
    dispatch({
      type,
      newText: event.target.value,
    })
  }

  const onClickConfirm = () => {
    return;
    // const destinationAddress = loginForm.forgotPasswordEmail;
    // console.log(destinationAddress);

    // if (!destinationAddress) {
    //   toast.error('Favor inserir seu endereço de e-mail!');
    //   return false;
    // }
  
    // if (!REGEX_EMAIL.test(destinationAddress)) {
    //   toast.error('Digite um e-mail válido!');
    //   return false;
    // }
  
    // return true;
    // // @Todo call email endpoint sending value from global state
  };

  return (
    <Wrapper>
      <StyledInput
        label="Assunto"
        // onChange={event =>
        //   dispatch({
        //     type: 'changeSubject',
        //     newText: event.target.value,
        //   })
        // }
        onChange={event => changeEvent(event, 'changeSubject')}
      />
      <StyledInput
        label="Seu nome"
        onChange={event =>
          dispatch({
            type: 'changeName',
            newText: event.target.value,
          })
        }
      />
      <StyledInput
        label="Seu melhor e-mail"
        onChange={event =>
          dispatch({
            type: 'changeEmail',
            newText: event.target.value,
          })
        }
      />
      <StyledInput
        label="Sua mensagem"
        onChange={event =>
          dispatch({
            type: 'changeMessage',
            newText: event.target.value,
          })
        }
      />
      <RedButton text="CONFIRMAR" click={onClickConfirm} />
    </Wrapper>
  );
};

export default ContactUsForm;
