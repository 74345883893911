import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import RightArrow from 'react-icons/lib/fa/angle-right';
import LeftArrow from 'react-icons/lib/fa/angle-left';
import Img from 'gatsby-image';

// import { medias } from '../utils/mediaQueries';
import { Button } from '../../components';
// import { Slider } from '../containers';
import { hexToRgb } from '../../utils/hexToRgb';
import './PortfolioCard.css';
import { theme } from '../../styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;

  align-self: center;
  padding: 1em;
  margin: 2em;

  background-color: ${theme.colors.white};
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)}; */
`;

const FullButton = styled(props => <Button {...props} />)`
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 100%;
  margin: 0 1em;
  font-size: 14px;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  height: 100%;
`;

const Title = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  text-align: left;
`;

const Text = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: 300;
  text-align: left;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const SliderImage = styled(Img)`
  border: 1px solid ${theme.colors.grey2};
  border-radius: 20px;
`;

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <LeftArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        left: '2.5%',
        zIndex: 1,
        top: '35%',
        height: 60,
        width: 40,
      }}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <RightArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        color: theme.colors.grey2,
        position: 'absolute',
        right: '2.5%',
        zIndex: 1,
        top: '35%',
        height: 60,
        width: 40,
      }}
      onClick={onClick}
    />
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  dotsClass: 'slick-dots',
  fade: true,
};

const PortfolioCard = ({ content }) => {
  const slides = content.map(({ id, title, text, backgroundImg }) => (
    <>
      <SliderImage
        key={id}
        Tag="section"
        fluid={backgroundImg}
      />
      <Content>
        <Title>{title}</Title>
        {/* <StyledLine /> */}
        <Text>{text}</Text>
      </Content>
    </>
  ));

  return (
    <Wrapper>
      <StyledSlider {...settings}>
        {slides}
      </StyledSlider>

      <FullButton
        text="SOLICITAR ORÇAMENTO"
        click={() => console.log('click')}
      />
    </Wrapper>
  );
};

PortfolioCard.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      backgroundImg: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default PortfolioCard;
