import React from 'react';

import { AdvantagesCard } from '../../components';

const SupplierAdvantages = ({ advantages }) => {
  const cards = advantages.map((props) => ( 
    <AdvantagesCard
      {...props}
      key={props.id}
      // id={id}
      // name={name}
      // supplier={supplier}
      // description={description}
      // savings={savings}
      // isFavorited={isFavorited}
      // details={details}
      // termsOfUse={termsOfUse}
      // cardImg={cardImg}
      // icon={icon}
    />)
  );

  return (
    <>
      {cards}
    </>
  );
};

export default SupplierAdvantages;
