import React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import {
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialInput,
} from '../components';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

import { useStateValue } from '../state';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';
import { StyledButton } from '../components/styled';
import { medias } from '../utils/mediaQueries';
import { useWindowWidth } from '../hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 90%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  @media ${medias.phoneMax} {
    align-items: flex-end;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.grey2};
`;

const Title = styled.h4`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeLg * 0.85}px;
`;

const StyledHalfInput = styled(InputMask)`
  width: 45%;
`;

const StyledFormControl = styled(FormControl)`
  width: 45%;
`;

const FranchiseForm = () => {
  const [
    {
      forms: { franchiseForm },
    },
    dispatch,
  ] = useStateValue();

  const screenWidth = useWindowWidth();
  const isMobile = screenWidth < 1024;

  const {
    name,
    cnpj,
    state,
    city,
    mainContact,
    phone,
    email,
    know,
    experience,
  } = franchiseForm;

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeFranchiseForm',
      newText,
      field,
    });
  };

  return (
    <Wrapper>
      <Card>
        <TitleContainer>
          <Title>Formulário de Interesse em Franquias</Title>
        </TitleContainer>
        <Line />
        <InputContainer>
          <RowContainer>
            <MaterialInput
              style={{ width: '45%' }}
              label="Nome da empresa"
              value={name}
              onChange={event => handleChange(event.target.value, 'name')}
            />
            <StyledHalfInput
              label="CNPJ"
              mask="99.999.999/9999-99"
              value={cnpj}
              onChange={event => handleChange(event.target.value, 'cnpj')}
            >
              {inputProps => <MaterialInput {...inputProps} />}
            </StyledHalfInput>
          </RowContainer>
          <RowContainer>
            <StyledFormControl>
              <MaterialInputLabel htmlFor="state-simple">
                Estado
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => handleChange(event.target.value, 'state')}
                value={state}
                inputProps={{
                  name: 'state',
                  id: 'state-simple',
                }}
              >
                <MaterialMenuItem value="MG">MG</MaterialMenuItem>
                <MaterialMenuItem value="SP">SP</MaterialMenuItem>
                <MaterialMenuItem value="RJ">RJ</MaterialMenuItem>
              </MaterialSelect>
            </StyledFormControl>
            <StyledFormControl>
              <MaterialInputLabel htmlFor="city-simple">
                Cidade
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => handleChange(event.target.value, 'city')}
                value={city}
                inputProps={{
                  name: 'city',
                  id: 'city-simple',
                }}
              >
                <MaterialMenuItem value="Belo Horizonte">
                  Belo Horizonte
                </MaterialMenuItem>
                <MaterialMenuItem value="São Paulo">São Paulo</MaterialMenuItem>
                <MaterialMenuItem value="Rio de Janeiro">
                  Rio de Janeiro
                </MaterialMenuItem>
              </MaterialSelect>
            </StyledFormControl>
          </RowContainer>
          <RowContainer>
            <StyledHalfInput
              label="Contato Principal"
              mask="(99)9999-9999"
              onChange={event =>
                handleChange(event.target.value, 'mainContact')
              }
              value={mainContact}
            >
              {inputProps => <MaterialInput {...inputProps} />}
            </StyledHalfInput>
            <StyledHalfInput
              label="Telefone"
              mask="(99)99999-9999"
              value={phone}
              onChange={event => handleChange(event.target.value, 'phone')}
            >
              {inputProps => <MaterialInput {...inputProps} />}
            </StyledHalfInput>
          </RowContainer>
          <RowContainer>
            <MaterialInput
              style={{ width: '45%' }}
              label="E-mail"
              value={email}
              onChange={event => handleChange(event.target.value, 'email')}
            />
            <MaterialInput
              style={{ width: '45%' }}
              label="Como ficou sabendo da rede?"
              value={know}
              onChange={event => handleChange(event.target.value, 'know')}
              multiline={isMobile ? true : false}
              rows="2"
            />
          </RowContainer>
          <MaterialInput
            style={{ width: '100%' }}
            label="Sua experiência no mercado de casamentos"
            value={experience}
            onChange={event => handleChange(event.target.value, 'experience')}
          />
          <StyledButton
            text="AVANÇAR"
            click={() => {
              if (
                !name ||
                !cnpj ||
                !state ||
                !city ||
                !mainContact ||
                !phone ||
                !email ||
                !know ||
                !experience
              ) {
                toast.error('Todos os campos são obrigatórios!');
                return false;
              }

              if (!REGEX_EMAIL.test(email)) {
                toast.error('Digite um e-mail válido!');
                return false;
              }

              return true;
            }}
          />
        </InputContainer>
      </Card>
    </Wrapper>
  );
};

export default FranchiseForm;
