import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import {
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialInput,
  MaterialDatePicker,
} from '../components';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';
import MomentUtils from '@date-io/moment';

import { useStateValue } from '../state';
import { StyledButton, StyledHalfButton } from '../components/styled';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 90%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 100%;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.grey2};
`;

const Title = styled.h4`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeLg * 0.85}px;
`;

const StyledHalfInput = styled(InputMask)`
  width: 45%;
`;

const StyledFormControl = styled(props => <FormControl {...props}/>)`
  width: 45%;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props}/>)`
  width: 45%;
`;

const SuppliersForm = props => {
  const [activePage, setActivePage] = useState(0);
  const [
    {
      forms: { supplierForm },
    },
    dispatch,
  ] = useStateValue();
  const {
    cnpj,
    company,
    trade,
    phone,
    date,
    state,
    city,
    commercialContact,
    email,
    instagram,
    site,
    region,
    know,
    activity,
    summary,
    brideNumber,
    contractsValue,
    marriageNumber,
    ceremonials,
    suppliers,
  } = supplierForm;

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeSupplierForm',
      newText,
      field,
    });
  };

  const handleArrayChange = (newText, field, position) => {
    const newArray = field === 'cerimonials' ? ceremonials : suppliers;

    const changedArray = {
      ...newArray,
      [position]: newText,
    };

    dispatch({
      type: 'changeSupplierForm',
      newText: changedArray,
      field,
    });
  };

  const { format } = props;

  return (
    <Wrapper>
      <Card>
        {activePage === 0 && (
          <>
            <TitleContainer>
              <Title>Fornecedor - Usuários</Title>
            </TitleContainer>
            <Line />
            <InputContainer>
              <MaterialInput
                style={{ width: '100%' }}
                label="Razão social"
                value={trade}
                onChange={event => handleChange(event.target.value, 'trade')}
              />
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Nome fantasia"
                  value={company}
                  onChange={event =>
                    handleChange(event.target.value, 'company')
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Email"
                  value={email}
                  onChange={event => handleChange(event.target.value, 'email')}
                />
              </RowContainer>
              <RowContainer>
                <StyledHalfInput
                  label="CNPJ"
                  mask="99.999.999/9999-99"
                  value={cnpj}
                  onChange={event => handleChange(event.target.value, 'cnpj')}
                >
                  {inputProps => <MaterialInput {...inputProps} />}
                </StyledHalfInput>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <StyledDatePicker
                    keyboard
                    label="Data de abertura da empresa"
                    mask={value =>
                      value
                        ? [
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]
                        : []
                    }
                    value={date}
                    format={format || 'DD/MM/YYYY'}
                    onChange={date => handleChange(date, 'date')}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                  />
                </MuiPickersUtilsProvider>
              </RowContainer>
              <RowContainer>
                <StyledHalfInput
                  label="Contato comercial"
                  mask="(99)9999-9999"
                  onChange={event =>
                    handleChange(event.target.value, 'commercialContact')
                  }
                  value={commercialContact}
                >
                  {inputProps => <MaterialInput {...inputProps} />}
                </StyledHalfInput>
                <StyledHalfInput
                  label="Telefone"
                  mask="(99)99999-9999"
                  value={phone}
                  onChange={event => handleChange(event.target.value, 'phone')}
                >
                  {inputProps => <MaterialInput {...inputProps} />}
                </StyledHalfInput>
              </RowContainer>
              <RowContainer>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="state-simple">Estado</MaterialInputLabel>
                  <MaterialSelect
                    onChange={event =>
                      handleChange(event.target.value, 'state')
                    }
                    value={state}
                    inputProps={{
                      name: 'state',
                      id: 'state-simple',
                    }}
                  >
                    <MaterialMenuItem value="MG">MG</MaterialMenuItem>
                    <MaterialMenuItem value="SP">SP</MaterialMenuItem>
                    <MaterialMenuItem value="RJ">RJ</MaterialMenuItem>
                  </MaterialSelect>
                </StyledFormControl>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="city-simple">Cidade</MaterialInputLabel>
                  <MaterialSelect
                    onChange={event => handleChange(event.target.value, 'city')}
                    value={city}
                    inputProps={{
                      name: 'city',
                      id: 'city-simple',
                    }}
                  >
                    <MaterialMenuItem value="Belo Horizonte">Belo Horizonte</MaterialMenuItem>
                    <MaterialMenuItem value="São Paulo">São Paulo</MaterialMenuItem>
                    <MaterialMenuItem value="Rio de Janeiro">Rio de Janeiro</MaterialMenuItem>
                  </MaterialSelect>
                </StyledFormControl>
              </RowContainer>
              <StyledButton
                text="AVANÇAR"
                click={() => {
                  if (
                    !company ||
                    !trade ||
                    !cnpj ||
                    !email ||
                    !date ||
                    !commercialContact ||
                    !phone ||
                    !state ||
                    !city
                  ) {
                    toast.error('Todos os campos são obrigatórios!');
                    return false;
                  }

                  if (!REGEX_EMAIL.test(email)) {
                    toast.error('Digite um e-mail válido!');
                    return false;
                  }

                  setActivePage(1);
                }}
              />
            </InputContainer>
          </>
        )}
        {activePage === 1 && (
          <>
            <TitleContainer>
              <Title>Fornecedor - Dados Básicos</Title>
            </TitleContainer>
            <Line />
            <InputContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Instagram"
                  value={instagram}
                  onChange={event =>
                    handleChange(event.target.value, 'instagram')
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Site"
                  value={site}
                  onChange={event => handleChange(event.target.value, 'site')}
                />
              </RowContainer>
              <MaterialInput
                label="Resumo da empresa"
                style={{ width: '100%' }}
                multiline
                rows="4"
                value={summary}
                onChange={event => handleChange(event.target.value, 'summary')}
              />
              <RowContainer>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="know-simple">
                    Onde conheceu a Vestidos de Branco
                  </MaterialInputLabel>
                  <MaterialSelect
                    onChange={event => handleChange(event.target.value, 'know')}
                    value={know}
                    inputProps={{
                      name: 'know',
                      id: 'know-simple',
                    }}
                  >
                    <MaterialMenuItem value="Redes Sociais">Redes Sociais</MaterialMenuItem>
                    <MaterialMenuItem value="Recomendações de amigos">
                      Recomendações de amigos
                    </MaterialMenuItem>
                    <MaterialMenuItem value="Recomendações de empresas">
                      Recomendações de empresas
                    </MaterialMenuItem>
                  </MaterialSelect>
                </StyledFormControl>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="region-simple">
                    Região de interesse
                  </MaterialInputLabel>
                  <MaterialSelect
                    onChange={event =>
                      handleChange(event.target.value, 'region')
                    }
                    value={region}
                    inputProps={{
                      name: 'region',
                      id: 'region-simple',
                    }}
                  >
                    <MaterialMenuItem value="MG">MG</MaterialMenuItem>
                    <MaterialMenuItem value="SP">SP</MaterialMenuItem>
                    <MaterialMenuItem value="RJ">RJ</MaterialMenuItem>
                  </MaterialSelect>
                </StyledFormControl>
              </RowContainer>
              <StyledFormControl style={{ width: '100%' }}>
                <MaterialInputLabel htmlFor="activity-simple">
                  Área de atuação
                </MaterialInputLabel>
                <MaterialSelect
                  onChange={event =>
                    handleChange(event.target.value, 'activity')
                  }
                  value={activity}
                  inputProps={{
                    name: 'activity',
                    id: 'activity-simple',
                  }}
                >
                  <MaterialMenuItem value="Cerveja artesanal">
                    Cerveja artesanal
                  </MaterialMenuItem>
                  <MaterialMenuItem value="Decoração">Decoração</MaterialMenuItem>
                  <MaterialMenuItem value="Lua de mel">Lua de mel</MaterialMenuItem>
                </MaterialSelect>
              </StyledFormControl>
              <RowContainer>
                <StyledHalfButton
                  text="VOLTAR"
                  click={() => setActivePage(0)}
                />
                <StyledHalfButton
                  text="AVANÇAR"
                  click={() => {
                    //@TODO CNPJ verification
                    if (
                      !instagram ||
                      !site ||
                      !cnpj ||
                      !email ||
                      !know ||
                      !region ||
                      !activity
                    ) {
                      toast.error('Todos os campos são obrigatórios!');
                      return false;
                    }

                    setActivePage(2);
                  }}
                />
              </RowContainer>
            </InputContainer>
          </>
        )}
        {activePage === 2 && (
          <>
            <TitleContainer>
              <Title>Fornecedor - Dados Complementares</Title>
            </TitleContainer>
            <Line />
            <InputContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Quantidade de noivos por mês"
                  value={brideNumber}
                  onChange={event =>
                    handleChange(event.target.value, 'brideNumber')
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Média de valor de contratos"
                  value={contractsValue}
                  onChange={event =>
                    handleChange(event.target.value, 'contractsValue')
                  }
                />
              </RowContainer>
              <MaterialInput
                style={{ width: '100%' }}
                label="Numéro de casamentos que realiza no mesmo dia"
                value={marriageNumber}
                onChange={event =>
                  handleChange(event.target.value, 'marriageNumber')
                }
              />
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Três cerimoinais próximos"
                  value={ceremonials[0]}
                  onChange={event =>
                    handleArrayChange(event.target.value, 'ceremonials', 0)
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Três fornecedores parceiros"
                  value={suppliers[0]}
                  onChange={event =>
                    handleArrayChange(event.target.value, 'suppliers', 0)
                  }
                />
              </RowContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  value={ceremonials[1]}
                  onChange={event =>
                    handleArrayChange(event.target.value, 'ceremonials', 1)
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  value={suppliers[1]}
                  onChange={event =>
                    handleArrayChange(event.target.value, 'suppliers', 1)
                  }
                />
              </RowContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  value={ceremonials[2]}
                  onChange={event =>
                    handleArrayChange(event.target.value, 'ceremonials', 2)
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  value={suppliers[2]}
                  onChange={event =>
                    handleArrayChange(event.target.value, 'suppliers', 2)
                  }
                />
              </RowContainer>
              <RowContainer>
                <StyledHalfButton
                  text="VOLTAR"
                  click={() => setActivePage(1)}
                />
                <StyledHalfButton
                  text="FINALIZAR"
                  //@TODO Endpoint to API
                  click={() => {
                    //@TODO CNPJ verification
                    if (
                      !brideNumber ||
                      !contractsValue ||
                      !marriageNumber ||
                      !ceremonials[0] ||
                      !ceremonials[1] ||
                      !ceremonials[2] ||
                      !suppliers[0] ||
                      !suppliers[1] ||
                      !suppliers[2]
                    ) {
                      toast.error('Todos os campos são obrigatórios!');
                      return false;
                    }

                    setActivePage(2);
                  }}
                />
              </RowContainer>
            </InputContainer>
          </>
        )}
      </Card>
    </Wrapper>
  );
};

SuppliersForm.propTypes = {
  format: PropTypes.string,
};

export default SuppliersForm;
