import React from 'react';
import {
  graphql,
  useStaticQuery
} from 'gatsby';

import SliderComponent from './Slider';

// @TODO: Change this to fetch images from server
export default () => {
  const data = useStaticQuery(graphql `
    query {
      bride: file(relativePath: { eq: "header1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      wedding: file(relativePath: { eq: "header2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  // Mock data
  const content = [{
      title: '1ª Certificação brasileira para fornecedores de eventos',
      text: 'Transmita qualidade e confiança para seus futuros clientes.',
      backgroundImg: data.bride.childImageSharp.fluid,
    },
    {
      title: 'CONFIANÇA',
      text: 'Certificação baseada em indicadores jurídicos, financeiros e qualitativos',
      backgroundImg: data.wedding.childImageSharp.fluid,
    },
  ];

  return ( <
    SliderComponent content = {
      content
    }
    />
  );
};